import {pathPrefix} from "../../gatsby-config";

export function getCurrentPathNav(pathname) {
    let currentPath =
        typeof window !== 'undefined'
            ? window.location.pathname.replace(pathPrefix, '')
            : '/'
    if (pathname) {
        currentPath = pathname
    }
    currentPath=currentPath.replace('/en/',"/")
    if (currentPath.indexOf('/docs/') !== -1) {
        return currentPath.replace(/\/docs\/(.+)\/.+/,"/docs/$1")
    }
    return currentPath
}

/**
 * 获取地址对应的路由地址-去除语言、结尾斜杠
 * @param pathname
 * @return {string}
 */
export function getCurrentPath(pathname) {
    let currentPath =
        typeof window !== 'undefined'
            ? window.location.pathname.replace(pathPrefix, '')
            : '/'
    if (pathname) {
        currentPath = pathname
    }
    currentPath=currentPath.replace('/en/',"/")
    currentPath=currentPath.replace(/(.+)\/$/,"$1")
    return currentPath
}

/**
 * 解决Link组件未占满容器，导致点击容器无法触发a链接
 * @param e
 */
export function handleLinkWrapClick(e) {
    const link = e.target.getElementsByTagName("a")
    if (link.length === 1) {
        link[0].click()
    }
}