/**
 * 在webview中发送消息给App
 * @param type
 * @param data
 */
export function postMessageToApp(type, data) {
    if (typeof window !== "undefined") {
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            type,
            data
        }))
    }

}