import * as React from 'react'
import Helmet from 'react-helmet'
import {Header} from './Header'
import {Sidebar} from './sidebar/sidebar'
import {useI18next} from "gatsby-plugin-react-i18next";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import {useEffect} from "react";
import {postMessageToApp} from "./tools/message";

const linkJumpCtrl = `
document.body.addEventListener('click',function(ev){
    var target = ev.target;
    var parent = document.getElementById("mdContent")
    if(parent && parent.contains(target) && target.tagName.toLowerCase() === 'a'){
        if (target.hostname !== window.location.hostname) {
            window.open(target.href)
            ev.preventDefault();
        }
    }
})
`

export function RootLayout({children,htmlClassName="",title, type, pathname}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const userAgent = typeof window !== 'undefined'
        ? window.navigator.userAgent
        : ''
    const [isInApp, set_isInApp] = React.useState(false);

    const {t} = useI18next();
    let pageTitle="MT Photos"
    if (title) {
        pageTitle = title
    }

    let pageRenderPage=1
    if(type === "full"){
        //隐藏 左侧导航
        pageRenderPage=2
    }else if(type === "all"){
        //隐藏 左侧导航 + 顶部导航
        pageRenderPage=3
    }
    if(isInApp){
        pageRenderPage=3
    }

    useEffect(() => {
        postMessageToApp("setTitle",pageTitle)
    }, [pageTitle])

    useEffect(() => {
        set_isInApp(userAgent.indexOf("MtPhotosApp/") !== -1)
    }, [userAgent])

    const renderContent = (type) => {
        if (pageRenderPage>1) {
            return <div className="page-content">
                {children}
            </div>
        }else{
            return <div className="page-with-side">
                <Sidebar isMobile={isMobile} pathname={pathname}/>
                <div className="page-content">
                    {children}
                </div>
            </div>
        }


    }

    return (
        <>
            <Helmet
                title={pageTitle}
                meta={[
                    {name: 'description', content: t("site_description")},
                ]}
                script={[{
                    type: 'text/javascript',
                    innerHTML: linkJumpCtrl
                }]}
            >
                <html lang="en" className={"page-"+htmlClassName}/>
            </Helmet>
            {
                pageRenderPage > 2 ?
                    null
                    :
                    <Header pathname={pathname}/>
            }
            {renderContent(type)}
        </>
    )
}

export default RootLayout
