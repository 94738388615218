import React, {useState} from 'react'
import {graphql, StaticQuery} from 'gatsby'

import {Collapse, Drawer, List, ListItemButton, ListItemText} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {getCurrentPath, handleLinkWrapClick} from "../tools/path";
import {useTranslation,Link} from "gatsby-plugin-react-i18next";
import Fab from "@mui/material/Fab";
import MenuBookIcon from '@mui/icons-material/MenuBook';

function isLinkItem(item) {
    return Boolean(item.link)
}

function NavPart({item, currentPath}) {
    const {t} = useTranslation();
    const {id} = item

    const [listOpenStatus, set_listOpenStatus] = useState(true)

    const toggleOpen = () => {
        set_listOpenStatus(!listOpenStatus)
    }
    if (isLinkItem(item)) {
        return (
            <ListItemButton className="lev1" onClick={handleLinkWrapClick}>
                <ListItemText className="lev1-text" primary={t(item.name)}/>
            </ListItemButton>
        )
    } else {
        const lev1Matched = item.items.filter(({link}) => {
            return link === currentPath
        }).length > 0

        return (
            <>
                <ListItemButton className={lev1Matched ? "on lev1" : "lev1"} onClick={toggleOpen}>
                    <ListItemText className="lev1-text" primary={t(item.name)}/>
                    {listOpenStatus ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={listOpenStatus} timeout="auto" unmountOnExit>
                    <List component="ul" className="lev2" disablePadding>
                        {
                            item.items.map(({name, link}, i) => {
                                return <ListItemButton component="li" className={link === currentPath ? "on" : ""}
                                                       key={i}
                                                       sx={{pl: 4}} onClick={handleLinkWrapClick}>
                                    <Link to={link}>
                                        <ListItemText primary={t(name)}/>
                                    </Link>
                                </ListItemButton>
                            })
                        }
                    </List>
                </Collapse>
            </>
        )

    }
}

function MobileSideBar({children}){
    const [open,setOpen]=useState(false)
    return <>
        <Fab
            sx={{
                position: "fixed",
                bottom: 30,
                right: 15,
                color:"#fff",
                zIndex:10
            }}
            className="mobile-side-nav"
            color="primary"
            size="medium"
            onClick={setOpen.bind(this, true)}>
            <MenuBookIcon/>
        </Fab>
        <Drawer
            anchor="left"
            open={open}
            onClose={setOpen.bind(this,false)}
        >
            {children}
        </Drawer>
    </>
}

export function Sidebar({pathname, isMobile}) {
    return (
        <StaticQuery
            query={graphql`
        query MyQuery {
          allSidebarJson {
            edges {
              node {
                id
                name
                link
                items {
                  name
                  link
                }
              }
            }
          }
        }
      `}
            render={(data) => {
                const rootItems = data.allSidebarJson.edges.map((v) => v.node)
                const currentPath = getCurrentPath(pathname)

                const content = <List
                    className="side-bar"
                    sx={{width: 180, bgcolor: 'background.paper'}}
                    component="nav"
                >
                    {rootItems.map((v, i) => <NavPart key={i} item={v} currentPath={currentPath}/>)}
                </List>

                if (isMobile) {
                    return <MobileSideBar>{content}</MobileSideBar>
                }

                return content
            }}
        />
    )
}
