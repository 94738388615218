import React from 'react'

import {AppBar,  Menu, Drawer, IconButton, List, ListItem, ListItemText, MenuItem, Toolbar,} from '@mui/material';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import TranslateIcon from '@mui/icons-material/Translate';
import logo from './images/logo.png'
import {useTranslation, Link, useI18next} from "gatsby-plugin-react-i18next";
import {getCurrentPathNav, handleLinkWrapClick} from "./tools/path";


function MobilePart({currentNav,pages,handleMenu}){

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return <Box sx={{flexGrow: 1, justifyContent: 'flex-end', display: {xs: 'flex', sm: 'none'}}}>
        <IconButton
            size="large"
            onClick={handleMenu}
            variant="outlined"
            color="inherit"
        >
            <TranslateIcon />
        </IconButton>

        <IconButton
            size="large"
            onClick={handleOpenNavMenu}
            color="inherit"
        >
            <MenuIcon/>
        </IconButton>

        <Drawer
            anchor="right"
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
        >
            <Box
                sx={{width: 250}}
                role="presentation"
                onClick={handleCloseNavMenu}
                onKeyDown={handleCloseNavMenu}
            >
                <List className="mobile-header-nav-list">
                    {pages.map(({text, link,navTag}, i) => (
                        <ListItem key={i} button className={currentNav === navTag ? "on" : ""}>
                            <Link to={link}>
                                <ListItemText primary={text}/>
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    </Box>
}

function PcPart({pages,currentNav,handleMenu}){
    return <Box className="top-nav" component="ul"
                sx={{flexGrow: 1, justifyContent: 'flex-end', display: {xs: 'none', sm: 'flex'}}}>
        {pages.map(({text, link,navTag}, i) => (
            <Box
                component='li'
                key={i}
                className={currentNav === navTag ? "on" : ""}
            >
                <Link to={link}>
                    {text}
                </Link>
            </Box>
        ))}
        <li>
            <Box component="a" sx={{mt:"-3px",ml:"5px"}} href="#" onClick={handleMenu}><TranslateIcon /></Box>
        </li>
    </Box>
}

export function Header(props) {
    const {t} = useTranslation();
    const {pathname} = props
    const {languages, originalPath} = useI18next();

    const currentNav = getCurrentPathNav(pathname)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const pages = [{
        text: t('nav_index'),
        link: "/",
        navTag:"/"
    }, {
        text: t('nav_start'),
        link: "/docs/start/introduction",
        navTag:"/docs/start"

    }, {
        text: t('nav_example'),
        link: "/docs/example/dsm",
        navTag:"/docs/example"
    }, {
        text: t('nav_guide'),
        link: "/docs/guide/install",
        navTag: "/docs/guide"
    }, {
        text: t('nav_advanced'),
        link: "/docs/advanced/practice",
        navTag: "/docs/advanced"
    }, {
        text: t('nav_contact'),
        link: "/contact",
        navTag: "/contact"
    }];



    return (
        <Box
            className="page-header"
            sx={{

            height: {
                sm: 69,
                xs: 56
            }
        }}
        >
            <AppBar sx={{}} position="static" color="transparent" elevation={0}>
                <Toolbar>
                    <Link to="/">
                        <Box sx={{width: 120, height: 24,}}>
                            <img src={logo} alt=""/>
                        </Box>
                    </Link>


                    <PcPart currentNav={currentNav} pages={pages} handleMenu={handleMenu}/>
                    <MobilePart currentNav={currentNav} pages={pages} handleMenu={handleMenu}/>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleLinkWrapClick}>
                            <Link to={originalPath} language="en">
                                English
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleLinkWrapClick}>
                            <Link to={originalPath} language="zh">
                                中文
                            </Link>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
